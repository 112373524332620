import React from "react";

function About() {
  return (
    <section id="about" className="component">
      <section className="wrapper">
        <section className="about">
          <div className="right1">
            <div data-aos="fade-right">
              <img src={require("../assets/images/about.png")} />
            </div>
          </div>
          <div className="detail">
            <div className="use1">
              <div data-aos="fade-right">
                <h1 className="heading1">Fast Delivery</h1>
              </div>
            </div>
            <div data-aos="fade-left">
              <p>
                Shop & receive everything you need in just 90 minutes. Assuring
                your mind soothes by experiencing the professional touch. Wait
                no more, all you need is just 90 minutes away from your
                doorstep. We assure 100% safe and hygienic distribution for our
                customers
              </p>
            </div>
          </div>
        </section>
        <section className="about">
          <div className="detail">
            <div className="user">
              <div data-aos="fade-right">
                <h1 className="heading1">User Friendly</h1>
              </div>
            </div>
            <div data-aos="fade-right">
              <p>
                New to the digital platform?? All you need to do is just ‘Get
                in, Give a click & Go ahead’
              </p>
            </div>
          </div>
          <div className="right1">
            <div data-aos="fade-left">
              <img src={require("../assets/images/about1.png")} />
            </div>
          </div>
        </section>
        <section className="about">
          <div className="right1">
            <div data-aos="fade-right">
              <img src={require("../assets/images/about2.png")} />
            </div>
          </div>
          <div className="detail">
            <div data-aos="fade-right">
              <h1 className="heading1">Dett Trust</h1>
            </div>
            <div data-aos="fade-left">
              <p>
                Looks so good on the outside, will make you feel good inside
                too. Experience quality bounded perfection from beginning to
                end. Guaranteed replacement for unsatisfactory grounds
              </p>
            </div>
          </div>
        </section>
        <section className="about">
          <div className="detail">
            <div className="user">
              <div data-aos="fade-right">
                <h1 className="heading1">24/7 Assistant</h1>
              </div>
            </div>
            <div data-aos="fade-right">
              <p>
                We're always going to be there for you at your service, stay
                connected with us Uninterrupted service through the whole day
                and night. Now and always stay connected.
              </p>
            </div>
          </div>
          <div className="last">
            <div data-aos="fade-left">
              <img src={require("../assets/images/about3.png")} />
            </div>
          </div>
        </section>
      </section>
    </section>
  );
}

export default About;
