import React from "react";

function Footer() {
  return (
    <section id="footer" className="footer">
      <div className="container-box">
        <div className="contact1">
          <div className="information">
            <div data-aos="fade-up" data-aos-anchor-placement="center-center">
              <h2 className="inform-1">Contact Us</h2>
            </div>
          </div>
          <div className="iconic">
            <div className="boxing_1">
              <div className="parent_1">
                <img src={require("../assets/images/telephone.png")} />
              </div>
              <div className="parent_2">
                <a class="link" href="tel:+91  858 999 8001">
                  91+ 858 999 8001
                </a>
              </div>
            </div>
            <div className="boxing_1">
              <div className="parent_1">
                <img src={require("../assets/images/location.png")} />
              </div>
              <div className="parent_2">
                <a
                  class="link"
                  href="https://goo.gl/maps/1GodGbciWr8XecpFA"
                  target="_blank"
                >
                  DETT Private limited CITTIC , CUSAT, South kalamasery Pin code
                  : 682022
                </a>
              </div>
            </div>
            <div className="boxing_1">
              <div className="parent_1">
                <img src={require("../assets/images/email.png")} />
              </div>
              <div className="parent_2">
                <a class="link" href="https://mail.google.com/" target="_blank">
                  hello@dett.app
                </a>
              </div>
            </div>
          </div>
          <div className="icons">
            <div className="icon">
              <a href="https://facebook.com/" target="_blank" title="hello">
                <img src={require("../assets/images/facebook.png")} />
              </a>
            </div>
            <div className="icon">
              <a href="https://twitter.com/" target="_blank" title="hello">
                <img src={require("../assets/images/twitter.png")} />
              </a>
            </div>
            <div className="icon">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                title="hello"
              >
                <img src={require("../assets/images/instagram.png")} />
              </a>
            </div>
          </div>
        </div>
        <div className="getintouch">
          <div data-aos="fade-up" data-aos-anchor-placement="center-center">
            <h2>Get in Touch</h2>
          </div>
          <form>
            <div className="qwer">
              <div className="hype">
                <p>
                  <input placeholder="First Name" type="text" />
                </p>
              </div>
              <div className="hello">
                <p>
                  <input placeholder="Last Name" type="text" />
                </p>
              </div>
            </div>
            <div className="dfg">
              <p>
                <input placeholder="Email" type="email" />
              </p>
            </div>
            <p className="pol">
              <textarea rows="10" cols="40" placeholder="Message"></textarea>
            </p>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <section className="bottom">
        <section className="wrapper">
          <p className="parent_2">
            © 2020 DeTT.All right reserved. | Powered by
            <a
              className="jkl"
              href="https://talrop.com/"
              target="_blank"
              title="talrop"
            >
              Talrop
            </a>
          </p>
        </section>
      </section>
    </section>
  );
}
export default Footer;
