import React from "react";
import Header from "./components/Header";
import About from "./components/About";
import Review from "./components/Review";
import Footer from "./components/Footer";
import "./assets/css/style.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const App = () => {
  return (
    <React.Fragment>
      <Header />
      <About />
      <Review />
      <Footer />
    </React.Fragment>
  );
};

export default App;
