import React, { useEffect, useState } from "react";

function Review() {
  const [rv_index, setRvIndex] = useState(0);
  const [isParagraph, setParagraph] = useState(false);

  const handleParagraph = () => {
    setParagraph(!isParagraph);
  };

  let reviews = [
    {
      id: 1,
      image: require("../assets/images/r1.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
    {
      id: 2,
      image: require("../assets/images/r2.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
    {
      id: 3,
      image: require("../assets/images/r3.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
    {
      id: 4,
      image: require("../assets/images/r4.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
    {
      id: 5,
      image: require("../assets/images/r1.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
    {
      id: 6,
      image: require("../assets/images/r1.png"),
      content:
        "Talrop Grolius is a creative and innovative web development agency aiming for a technology revolution in society. The young designers, developers and managers are forever committed to a hundred percent efficiency.",
    },
  ];

  const MyPara = () => {
    return (
      <div data-aos="fade-left" data-aos-anchor-placement="top-center">
        <p>"{reviews[rv_index].content}"</p>
      </div>
    );
  };

  // useEffect(() => {
  //   return <MyPara />;
  // }, []);

  return (
    <section id="review" className="review">
      <section className="wrapper">
        <div data-aos="zoom-out-down">
          <h1 className="room">Review</h1>
        </div>
        <div className="hander">
          <div className="pixel">
            <img src={reviews[rv_index].image} />
          </div>
          <div className="writer">
            <MyPara />
          </div>
        </div>
        <div className="backend">
          {reviews.map((item, index) => (
            <div className="pit" onClick={() => setRvIndex(index)}>
              <div
                className="triangle"
                style={{
                  display: index == rv_index ? "block" : "none",
                }}
              ></div>
              <img src={item.image} />
            </div>
          ))}
        </div>
        <img className="review1" src={require("../assets/images/left.png")} />
      </section>
    </section>
  );
}

export default Review;
