import React from "react";

function Header() {
  return (
    <section id="header" className="header">
      <section className="wrapper">
        <section className="heading">
          <h1 className="logo">
            <a href="#">
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img src={require("../assets/images/dima.png")} alt="logo" />
              </div>
            </a>
          </h1>
          <ul>
            <div
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <l1>
                <a className="home" href="/#header">
                  Home
                </a>
              </l1>
              <l1>
                <a className="feature" href="/#about">
                  Features
                </a>
              </l1>
              <l1>
                <a className="contact" href="/#footer">
                  Contact
                </a>
              </l1>
            </div>
          </ul>
        </section>
        <section className="spotlight">
          <div>
            <div className="left">
              <div
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <h1>Hello,</h1>
              </div>
              <div data-aos="fade-left">
                <p>
                  You are just a fingertip away from exploring the vast supply
                  of your needy.
                  <br /> You choose & we bring it to you. No matter where ever
                  you are,
                  <br /> all your needs will reach at your doorstep. <br /> Now
                  the question is why wait !?
                </p>
              </div>
              <div className="store">
                <a
                  href="https://www.apple.com/in/app-store/"
                  target="_blank"
                  title="apple"
                >
                  <img
                    className="img1"
                    src={require("../assets/images/appstore.png")}
                    alt="logo"
                  />
                </a>
                <a
                  href="https://play.google.com/store"
                  target="_blank"
                  title="apple"
                >
                  <img
                    className="img2"
                    src={require("../assets/images/google.png")}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="flexcontainer">
                <div className="icon1">
                  <a href="https://facebook.com" target="_blank" title="hello">
                    <span>
                      <div
                        data-aos="fade-right"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500"
                      >
                        <img src={require("../assets/images/facebook.png")} />
                      </div>
                    </span>
                  </a>
                </div>
                <div className="icon1">
                  <a href="https://twitter.com" target="_blank" title="hello">
                    <span>
                      <img src={require("../assets/images/twitter.png")} />
                    </span>
                  </a>
                </div>
                <div className="icon1">
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    title="hello"
                  >
                    <span>
                      <div
                        data-aos="fade-left"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500"
                      >
                        <img src={require("../assets/images/instagram.png")} />
                      </div>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="right">
        <img src={require("../assets/images/spotlight.png")} />
      </div>
    </section>
  );
}

export default Header;
